.colorwars > .configure {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.colorwars > .configure > button {
  outline: 0;
  border: 3px solid rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0);
  font-size: 28px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  padding: 3px 40px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.5s all;
}
.colorwars > .configure > button:hover {
  padding: 3px 75px;
}
.colorwars > .configure > .configureItem {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  /* align-items: flex-start; */
}
.colorwars > .configure > .configureItem > .header {
  font-size: 28px;
  font-weight: 700;
}
.colorwars > .configure > .configureItem > .items {
  display: flex;
  align-items: center;
  gap: 20px;
}
.colorwars > .configure > .configureItem > .items > .item {
  border: 3px solid;
  border-color: rgb(148, 148, 148);
  border-radius: 30px;
  background-color: rgba(128, 128, 128, 0.336);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  cursor: pointer;
  font-weight: 500;
  font-size: 32px;
  color: rgb(39, 44, 41);
}
.colorwars > .configure > .configureItem > .items > .activeItem {
  color: rgb(0, 66, 28);
  background-color: rgba(89, 197, 98, 0.336);
  border-color: lime;
}

@media screen and (max-width: 650px) {
  .colorwars > .configure > .configureItem > .items {
    gap: 15px;
  }
  .colorwars > .configure > .configureItem > .items > .item {
    width: 140px;
    height: 140px;
    font-size: 26px;
  }
}
@media screen and (max-width: 475px) {
  .colorwars > .configure > .configureItem > .items {
    gap: 7.5px;
  }
  .colorwars > .configure > .configureItem > .items > .item {
    width: 100px;
    height: 100px;
    font-size: 20px;
    border-radius: 20px;
    border-width: 2px;
  }
  .colorwars > .configure > .configureItem > .header {
    font-size: 22px;
    font-weight: 700;
  }
}
