.colorwars > .war {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.colorwars > .war > .leaderboard {
  width: 200px;
  text-align: left;
}
.colorwars > .war > .leaderboard > .header,
.colorwars > .war > .settings > .header {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 5px;
}
.colorwars > .war > .settings {
  width: 250px;
  text-align: left;
  margin-right: 5px;
}
@media screen and (max-width: 1100px) {
  .colorwars > .war > .leaderboard {
    width: 150px;
  }
  .colorwars > .war > canvas {
    height: 400px;
  }
  .colorwars > .war > .leaderboard > .header,
  .colorwars > .war > .settings > .header {
    font-size: 18px;
  }
}
@media screen and (max-width: 850px) {
  .colorwars > .war > canvas {
    height: 300px;
  }
  .colorwars > .war > .leaderboard {
    width: 120px;
  }
}
@media screen and (max-width: 750px) {
  .colorwars > .war {
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    gap: 20px;
  }
  .colorwars > .war > .leaderboard {
    width: fit-content;
  }
  .colorwars > .war > .settings {
    width: fit-content;
    margin-right: 0px;
  }
  .colorwars > .war > .settings > .header {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .colorwars > .war > canvas {
    height: 250px;
  }
}
